<script setup lang="ts">
import { useLayoutStore } from '~/stores/layout.store';
import { useUserStore } from '~/stores/user.store';
const userStore = useUserStore();
const user = computed(() => userStore.userGetter());

const props = defineProps({
  country_id: {
    type: Number,
    required: true,
  }
})
const layoutStore = useLayoutStore();

const menus = ref([]);

const menusInHeaders = await layoutStore.fetchMenusInHeader('blog', props.country_id);
menus.value = menusInHeaders;
</script>

<template>
	<div class="border-b border-gray-200 py-2 mb-8">
		<div class="container mx-auto max-w-7xl px-4 sm:px-2 lg:px-8">
			<nav class="flex gap-x-8">
				<template v-for="menu in menus">
					<NuxtLink  v-if="!menu.only_for_logged_in_users||user!=undefined"  :to="localePath(`/${menu.url}`)" class="text-sm text-neutral-600 py-2"
					aria-current="page">{{ menu.name }}</NuxtLink>
				</template>
			
			</nav>
		</div>
	</div>
</template>
